import './home/Home.css'
import React from 'react'
import { SectionHeader } from './home/SectionHeader';
import { SectionYear } from './home/SectionYear';
import { SectionIntro } from './home/SectionIntro';

const Home = () => {
    return <div className='Home'>
        <SectionIntro />
        {/* <SectionHeader />
        <SectionYear year="1" />
        <SectionYear year="2" />
        <SectionYear year="3" /> */}
    </div>
}

export default Home;